@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans Light'), local('NotoSans-Light'),
      url('../../public/fonts/NotoSans/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans Bold'), local('NotoSans-Bold'),
      url('../../public/fonts/NotoSans/NotoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans Medium'), local('NotoSans-Medium'),
      url('../../public/fonts/NotoSans/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans SemiBold'), local('NotoSans-SemiBold'),
      url('../../public/fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans ExtraBold'), local('NotoSans-ExtraBold'),
      url('../../public/fonts/NotoSans/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
