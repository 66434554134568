@import './font-face.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  box-sizing: border-box;
  font-family: 'NotoSans';
}
html,
body,
#__next {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tooltip-container {
  --tooltipBackground: #4a4a4a !important;
  --tooltipBorder: #4a4a4a !important;
}
